<template>
  <!-- 礼物管理页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="24" :sm="24" style="margin-bottom: 20px">
                  <a-button style="border-radius: 2px" type="primary" @click="$refs.addForm.add()">添加轮播图</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <span slot="type" slot-scope="text, record">
              <span v-if="+text === 1">商品</span>
              <span v-if="+text === 2">商品分类</span>
              <span v-if="+text === 3">富文本</span>
            </span>
            <span slot="image" slot-scope="text, record">
              <viewer :images="[record.image]">
                <img
                  :src="record.image"
                  style="width: 50px; height: 50px; border: 0.5px solid #ccc; padding: 5px; margin: 3px"
                  alt=""
                />
              </viewer>
            </span>

            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.editForm.edit(record)">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm lacement="topRight" title="确认删除？" @confirm="() => giftDelete(record)">
                <a>删除</a>
              </a-popconfirm>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <add-form
      ref="addForm"
      :areaId="areaId"
      @ok="
        () => {
          this.initTableData()
        }
      "
    ></add-form>
    <edit-form
      ref="editForm"
      :areaId="areaId"
      @ok="
        () => {
          this.getList()
        }
      "
    ></edit-form>
  </div>
</template>

<script>
import addForm from './addForm.vue'
import editForm from './editForm.vue'
import { bannerDelete, bannerPage } from '@/api/modular/mallLiving/banner'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '类型',
    align: 'center',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },

  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort',
    align: 'center',
  },
  {
    title: '轮播图',
    dataIndex: 'image',
    key: 'image',
    align: 'center',
    scopedSlots: { customRender: 'image' },
  },

  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'center',
  },
  {
    title: '操作',
    align: 'center',
    dataIndex: 'action',
    fixed: 'right',
    key: 'action',
    width: '200px',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      recordItem: null,
      loading: false,
      queryParam: {},
      giftStatusList: [
        { status: 1, name: '启用' },
        { status: 3, name: '禁用' },
      ],
      areaId: '',
    }
  },
  components: {
    addForm,
    editForm,
  },

  mounted() {
    this.areaId = this.$route.query.areaId
    this.initTableData()
  },
  methods: {
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    clickSwitch(record) {
      this.recordItem = record
    },

    changeSwitch(e) {
      let delFlag = e ? 1 : 3
      setTimeout(() => {
        this.changeTypeStatus(delFlag)
      }, 300)
    },
    giftDelete(record) {
      this.recordItem = record
      this.changeTypeStatus(2)
    },
    //切换礼物状态函数（启用/禁用/删除）
    async changeTypeStatus(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await bannerDelete(params)
      var text = delFlag == 1 ? '启用' : delFlag == 2 ? '删除' : '禁用'
      if (data.code == 200) {
        this.$message.success(text + '轮播图成功')
        this.getList()
      }
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: { ...this.queryParam, areaId: this.areaId },
        page: this.page,
      }
      bannerPage(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 40px;
  height: 40px;
}
</style>
